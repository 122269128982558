<template>
  <section class="chart-conversation-list__wrapper">
    <ul>
      <li
        v-for="(conversation, index) in conversations"
        :key="`conversation-${index}`">
        <!-- <router-link></router-link> -->
        <ChatConversationItem v-bind="conversation"></ChatConversationItem>
      </li>
    </ul>
  </section>
</template>

<script>

import ChatConversationItem     from './o-ChatConversationItem.vue';


export default {
  name: 'o-ChatConversationsList',
  components: {
    ChatConversationItem,
  },
  data: () => ({
    conversations: [
      {
        author: 'Michel Martoni (link)',
        startDate: '28/04',
        lastMessageDate: 'Vendredi 05 mai 2021',
        message: 'Altera sententia est, quae definit amicit…',
        status: 'active',
        id: 12345,
      },
      {
        author: 'Michel Martoni (link)',
        startDate: '28/04',
        lastMessageDate: 'Vendredi 05 mai 2021',
        message: 'Altera sententia est, quae definit amicit…',
        status: '',
        id: 12346,
      },
      {
        author: 'Michel Martoni (link)',
        startDate: '28/04',
        lastMessageDate: 'Vendredi 05 mai 2021',
        message: 'Altera sententia est, quae definit amicit…',
        status: 'unread',
        id: 12347,
      },
      {
        author: 'Michel Martoni (link)',
        startDate: '28/04',
        lastMessageDate: 'Vendredi 05 mai 2021',
        message: 'Altera sententia est, quae definit amicit…',
        status: 'unread',
        id: 12348,
      },
    ],
  }),
}

</script>

<style lang="scss">

.chart-conversation-list {
  &__wrapper {
    border-right: 1px solid var(--color-grey-semi);
  }
}

</style>
