<template>
  <div :class="[
    wrapperStyle,
    'chat-conversation-item__wrapper',
  ]">
    <Heading
      :level="'h4'"
      class="chat-conversation-item__author">
      {{ author }}
    </Heading>
    <span
      v-if="status === 'unread'"
      class="chat-conversation-item__unread-dot">
    </span>
    <Paragraph
      :tag="'span'"
      class="chat-conversation-item__start-date">
      {{ startDate }}
    </Paragraph>
    <Paragraph
      :tag="'span'"
      class="chat-conversation-item__last-message-date">
      {{ lastMessageDate }}
    </Paragraph>
    <Paragraph
      :tag="'span'"
      :size="'sm'"
      class="chat-conversation-item__message">
      {{ message }}
    </Paragraph>
  </div>
</template>

<script>

import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'o-ChatConversationItem',
  components: {
    Heading,
    Paragraph,
  },
  props: {
    status: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true,
    },
    lastMessageDate: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    wrapperStyle() {
      return {
        'chat-conversation-item__wrapper--active': this.status === 'active',
        'chat-conversation-item__wrapper--unread': this.status === 'unread',
      };
    },
  },
}

</script>

<style lang="scss">

.chat-conversation-item {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr auto min-content;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "author start-date unread-dot"
      "last-message-date last-message-date last-message-date"
      "message message message"
    ;
    grid-column-gap: var(--space-md);
    align-items: center;
    padding: var(--space-md) var(--space-lg);
    padding-bottom: var(--space-lg);

    &--active {
      background-color: var(--color-grey-background);
    }

    &--unread {
      background-color: var(--color-grey-light);
    }
  }

  &__author {
    grid-area: author;
  }

  &__start-date {
    grid-area: start-date;
  }

  &__last-message-date {
    grid-area: last-message-date;
    margin-bottom: var(--space-sm);
  }

  &__message {
    grid-area: message;
  }

  &__unread-dot {
    grid-area: unread-dot;
    height: 6px;
    width: 6px;
    border-radius: var(--rounded-circle);
    background-color: var(--color-black);
  }
}

</style>
